.charityItem .thumb {
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  z-index: 0;
}

.charityItem .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width : 251px;
  margin-left: 1px;
  min-height: 20px;
  padding-left: 4px;
  padding-bottom: 4px;
  background-color: rgba(92, 92, 92, 0.2);
  border-radius: 0px 0px 12px 12px;
  position: relative;
  z-index: 1;
  bottom :50px;
}

.charityItem .title {
  font-size: 14px;
  color: black;
  padding-left: 15px;
}

.charityItem a {
  text-decoration: none;
  color: inherit;
}

.charityItem a:visited {
  color: inherit;
}
