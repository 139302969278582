.header {
  background-color: #fff;
}

.header .content {
  padding: 65px 10px 20px;
  text-align: left;
}

.header .title {
  margin: 10px auto 20px;
  font-size: 28px;
  font-weight: 500;
  color: black;
  text-align: left;
}

.challengename {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.challengename p {
  display: flex;
  margin: 0;
  margin-top: 23px;
  margin-left: 25px;
}

.detail {
  font-weight: bold;
  font-size: 20px;
}

.shopIcon {
  width: 300px;
  height: auto;
  border-radius: 10px;
  align-self: flex-end;
}

.shopDetailImg {
  width: 1000px;
  height: 500px;
  background-color: #eaeaea;
  padding: 20px;
  margin-left: 0;
  border-radius: 10px;
}

.shopHeader {
  display: flex;
  justify-content: space-between;
}

.bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.topics {
  margin: 30px auto 100px;
}

.topic {
  padding: 30px;
}

.topic:not(:last-child) {
  margin-bottom: 20px;
}

.topic .title {
  margin: 0 auto 10px;
  font-size: 15px;
  font-weight: 500;
  color: black;
  align-items: left;
}

.center {
  display: flex;
  justify-content: center;
}

.temp {
  display: flex;
  flex-direction: column;
}

.temp h3 {
  color: #dd518c;
  font-size: 21px;
  display: flex;
}

.cost {
  color: black;
  font-weight: bold;
  margin-right: 10px;
}

.centerdetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.centerdetail img {
  width: 650px;
}
