/* MainButton.module.css */
.mainButton {
  padding: 15px 30px;
  color: #dd518c;
  background-color: #fff;
  border: 1.5px solid #dd518c;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;

  /* Additional styles for hover state */
  &:hover {
    background-color: #dd518c;
    color: white;
  }

  /* Additional styles for active state */
  &:active {
    background-color: #e7e7e7;
  }

  /* Additional styles for focus state */
  &:focus {
    border-color: #555;
  }
}
