.header {
  background-color: #fff;
}

.header .content {
  padding: 65px 10px 20px;
  text-align: left;
}

.header .title {
  margin: 10px auto 20px;
  font-size: 28px;
  font-weight: medium;
  color: black;
  text-align: left;
}

.charityIcon {
  width: auto;
  height: auto;

  border-radius: 8px;
  align-self: flex-end;
}

.charityHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.topics {
  margin: 30px auto 100px;
}

.topic {
  padding: 30px;
}

.topic:not(:last-child) {
  margin-bottom: 20px;
}

.topic .title {
  margin: 0 auto 10px;
  font-size: 15px;
  font-weight: 500;
  color: black;
  align-items: left;
}

.center {
  display: flex;
  justify-content: center;
}
