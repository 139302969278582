/* 탭 버튼을 포함하는 컨테이너의 스타일 */
.tabContainer {
  display: flex;
  flex-wrap: nowrap; /* 탭 버튼이 한 줄에 표시되도록 설정 */
  justify-content: space-between; /* 양쪽 정렬 */
  width: 100%; /* 컨테이너의 너비를 최대로 설정 */
  align-items: center; /* 세로축 중앙 정렬 */
  margin-bottom: 20px;
}

/* 개별 탭 버튼의 스타일 */
.tabButton {
  display: flex; /* Flexbox를 사용 */
  align-items: center; /* 수직 방향 중앙 정렬 */
  justify-content: center; /* 가로 방향 중앙 정렬 추가 */
  margin: 3px;
  background-color: #eaeaea;
  flex-grow: 1; /* 탭 버튼이 공간을 균등하게 차지하도록 설정 */
  padding: 8px 8px; /* 내부 여백 */
  cursor: pointer; /* 마우스 오버 시 커서 변경 */
  font-size: 14px; /* 글꼴 크기 */
  transition: all 0.2s; /* 트랜지션 효과 */
  border-radius: 20px;
  border: 1px solid transparent; /* 투명한 테두리 추가 */
  text-align: center; /* 텍스트 중앙 정렬 */
  color: black; /* 탭의 기본 글자 색 */
}

.tabButton:hover {
  border: 1px solid #b0b0b0; /* 호버 상태의 테두리 색상 - 약간 더 진한 색 */
}

.tabButton.active {
  background-color: black;
  color: white;
}

.tabButton:first-child {
  background-color: #f8d7da; /* 연한 핑크색 - 기본 상태 */
}

.tabButton:first-child.active {
  background-color: #f06292; /* 진한 핑크색 - 활성 상태와 호버 상태 */
}
.tabButton:first-child:hover {
  border: 1px solid #f06292; /* 호버 상태의 테두리 색상 - 약간 더 진한 색 */
}

.tabButton:last-child {
  background-color: white; /* 맨 마지막 탭의 배경 색을 흰색으로 설정 */
  color: black; /* 맨 마지막 탭의 텍스트 색을 검정색으로 설정 */
  border: 1px solid #b0b0b0; /* 맨 마지막 탭이 호버 상태일 때의 테두리 색상 - 약간 더 진한 색 */
}

.tabButton:last-child.active {
  background-color: black; /* 맨 마지막 탭이 활성 상태일 때의 배경 색을 검정색으로 설정 */
  color: white; /* 맨 마지막 탭이 활성 상태일 때의 텍스트 색을 흰색으로 설정 */
}

.tabButton:last-child:hover {
  background-color: black; /* 맨 마지막 탭이 활성 상태일 때의 배경 색을 검정색으로 설정 */
  color: white; /* 맨 마지막 탭이 활성 상태일 때의 텍스트 색을 흰색으로 설정 */
}

.tabIcon {
  width: 20px; /* 아이콘의 너비 */
  height: 20px; /* 아이콘의 높이 */
  margin-right: 5px; /* 아이콘과 탭 이름 사이의 간격 */
}

.icon {
  height: 19px;
  width: 19px;
  margin-right: 8px;
}
