.bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 60px;
}

.bg.catalog {
  background-color: #ffffff;
}

.bg.community {
  background-color: #ffffff;
}

.heading {
  margin: 50px 0 5px;
  font-size: 30px;
  font-weight: 500;
}

.catalog .heading {
  color: #000000;
}

.community .heading,
.community .description {
  color: #000000;
}

.container {
  margin-top: -20px;
  margin-bottom: 100px;
}
