.shopItem .thumb {
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  z-index: 0;
}

.shopItem .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 252px;
  margin-left: 2px;
  min-height: 20px;
  padding-left: 4px;
  padding-bottom: 4px;
  background-color: rgba(92, 92, 92, 0.2);
  border-radius: 0px 0px 12px 12px;
  position: relative;
  z-index: 1;
  bottom: 50px;
}

.shopItem .title {
  font-size: 14px;
  color: black;
  padding-left: 15px;
}

.shopItem a {
  text-decoration: none;
  color: inherit;
}

.shopItem a:visited {
  color: inherit;
}

.shopItem .details {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.shopItem .cost {
  margin-right: 7px;
}
