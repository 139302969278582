.charityList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

@media (max-width: 840px) {
  .charityList {
    grid-template-columns: repeat(2, 1fr);
  }
}

.emptyList {
  margin: 40px auto;
}

.count {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 300;
}

.infoBar {
  display: flex;
  justify-content: space-between; /* 오른쪽 정렬 */
  align-items: center; /* 수직 중앙 정렬 (필요한 경우) */
  margin: 20px 0; /* 상하 마진 조정 */
}

.infoBar > * {
  margin-right: 10px; /* 요소들 사이의 간격 */
}

.infoBar > :last-child {
  margin-right: 0; /* 마지막 요소의 마진 제거 */
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin: 5px;
}

.pagination li a {
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pagination li a:hover,
.pagination li a.active {
  background-color: #ddd;
}

.center {
  display: flex;
  justify-content: center;
}
