/* MainButton.module.css */
.mainButton {
  padding: 10px 20px;
  background-color: #fff;
  border: 1.5px solid #000;
  border-radius: 20px;
  font-size: 15px;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease;

  /* Additional styles for hover state */
  &:hover {
    background-color: #dd518c;
    color: white;
    border: white;
  }

  /* Additional styles for active state */
  &:active {
    background-color: #e7e7e7;
  }

  /* Additional styles for focus state */
  &:focus {
    border-color: #555;
  }
}
