.charityList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  align-items: start;
  grid-auto-rows: auto; /* 각 행의 높이를 그리드 아이템의 높이에 따라 자동으로 설정 */
}

@media (max-width: 840px) {
  .charityList {
    grid-template-columns: repeat(2, 1fr);
  }
}

.emptyList {
  margin: 40px auto;
}

.count {
  margin: 20px 0;
  font-size: 14px;
  font-weight: 300;
}

.infoBar {
  display: flex;
  justify-content: flex-end; /* 오른쪽 정렬 */
  align-items: center; /* 수직 중앙 정렬 (필요한 경우) */
  margin: 20px 0; /* 상하 마진 조정 */
}

.infoBar > * {
  margin-right: 10px; /* 요소들 사이의 간격 */
}

.infoBar > :last-child {
  margin-right: 0; /* 마지막 요소의 마진 제거 */
}

.center {
  display: flex;
  justify-content: center;
}
